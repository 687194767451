import { WalletIcon } from "@heroicons/react/20/solid";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { formatWalletAddress } from "../utils/wallets";
import { setWalletAddress } from "../redux/slices/user";
import { useEffect } from "react";

export default function ConnectWalletButton(props: { className?: string }) {
  const { walletAddress } = useAppSelector((state) => state.user);
  console.log(walletAddress);
  const dispatch = useAppDispatch();

  const connectWallet = async () => {
    console.log("Requesting wallet connection...");
    try {
      const { ethereum } = window as any;
      console.log(ethereum);
      if (ethereum) {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        console.log(accounts);
        return accounts[0];
      } else {
        alert("MetaMask is not installed. Please install it to use this app.");
      }
    } catch (error) {
      console.error("An error occurred during wallet connection:", error);
    }
  };

  useEffect(() => {
    connectWallet().then((acc) => {
      dispatch(setWalletAddress(acc));
    });
  }, []);

  if (!walletAddress) {
    return (
      <button
        onClick={async () => {
          const acc = await connectWallet();
          dispatch(setWalletAddress(acc));
        }}
        className={
          "flex py-2 px-3 justify-center items-center text-white bg-blue-500 rounded-lg text-xs gap-1" +
          (props.className ? " " + props.className : "")
        }
      >
        Connect wallet <WalletIcon style={{ width: 16, height: 16 }} />
      </button>
    );
  } else
    return (
      <div
        className={
          "text-white text-center" + (props.className ? props.className : "")
        }
      >
        Connected to: {formatWalletAddress(walletAddress)}
      </div>
    );
}
