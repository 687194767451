import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

// Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCi1YVJ_TTT0dSplo1H5oyZt3ptntruYgk",
    authDomain: "sphereone-testing.firebaseapp.com",
    databaseURL: "https://sphereone-testing-default-rtdb.firebaseio.com",
    projectId: "sphereone-testing",
    storageBucket: "sphereone-testing.appspot.com",
    messagingSenderId: "224389963303",
    appId: "1:224389963303:web:80059d495a8d84824af802",
    measurementId: "G-EKP9BK8NF7"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
