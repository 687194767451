export const caseName = (name: string | undefined) => {
  return name
    ? name
        .split(" ")
        .map((str) => str[0]?.toLocaleUpperCase())
        .join("")
    : "";
};

function extractProtocolNameFromError(errorMessage: string) {
  const match = /protocol: (\w+)/.exec(errorMessage);
  return match ? match[1] : null;
}

function extractProtocolNameFromPhrase(protocolPhrase: string) {
  const match = /Create agent for (\w+)/.exec(protocolPhrase);
  return match ? match[1] : null;
}

export function compareProtocolNames(
  errorMessage: string,
  protocolPhrase: string
) {
  const protocolNameFromError = extractProtocolNameFromError(errorMessage);
  const protocolNameFromPhrase = extractProtocolNameFromPhrase(protocolPhrase);

  return !!(
    protocolNameFromError &&
    protocolNameFromPhrase &&
    protocolNameFromError.toLowerCase() === protocolNameFromPhrase.toLowerCase()
  );
}
