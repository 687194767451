import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  loading?: boolean;
  error?: string;
  walletAddress?: string;
}

const initialState: UserState = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
  },
});

export const { setWalletAddress } = userSlice.actions;
export default userSlice.reducer;
