import React from "react";
import {
  AlertDialog as AlertDialogPrimitive,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../primitives/AlertDialogPrimitive";

interface AlertDialogProps {
  open: boolean;
  title: string;
  description: any;
  actionLabel: string;
  onActionPress: () => void;
  cancelLabel?: string;
  onCancelPress?: () => void;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  title,
  description,
  actionLabel,
  onActionPress,
  cancelLabel,
  onCancelPress,
}) => {
  return (
    <AlertDialogPrimitive open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction onClick={onActionPress}>
            {actionLabel}
          </AlertDialogAction>
          {cancelLabel && onCancelPress && (
            <AlertDialogCancel onClick={onCancelPress}>
              {cancelLabel}
            </AlertDialogCancel>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogPrimitive>
  );
};
