import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { requestSOLSignature, requestSignature } from "../utils/wallets";
import { useAppSelector } from "../redux/hooks";
import { generateAndSetConversationStarters } from "../utils/chat";
import { socketInstance } from "../services/socketIo";

export enum CreatorResponseBadges {
  CHAIN_INFO = "chain_info",
  TRANSFER_CODE_MESSAGES = "transfer_code_messages",
  DEX_CODE_MESSAGES = "dex_code_messages",
  BRIDGE_CODE_MESSAGES = "bridge_code_messages",
}

export interface Message {
  agent: "ai" | "human";
  message: string;
}

const messagesInMemory: Message[] = [];

export function useChat() {
  const [socket, setSocket] = useState<any>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [chatId, setChatId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [error, setError] = useState<string>("");
  const [conversationStarters, setConversationStarters] = useState<string[]>(
    []
  );
  const { walletAddress } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (chatId) return;
    if (socket) return;

    // create a new chat
    socketInstance.on("connect", () => {
      setSocket(socketInstance);
      setChatId(socketInstance.id as string);
      setLoading(false);
    });

    // listen for messages from the AI
    socketInstance.on("data", async (data: any) => {
      if (!data || !data?.data) return;

      const ai_message = data.data;
      if (!messagesInMemory.length) {
        setMessages(() => [
          ...messagesInMemory,
          { agent: "ai", message: ai_message },
        ]);
      } else {
        // if the last message of the array is from AI it means that the message received should be appended to the last message
        // we check if the last message is from AI because the last message could be from the user
        const isLastMessageFromAi =
          messagesInMemory[messagesInMemory.length - 1]?.agent === "ai";
        // we append the message to the last message from AI
        if (isLastMessageFromAi) {
          // if the last message is from AI we remove it from the array and append the new message
          const messageToAdd = isLastMessageFromAi
            ? messagesInMemory[messagesInMemory.length - 1].message +
              "\n" +
              ai_message
            : ai_message;

          messagesInMemory.pop();
          messagesInMemory.push({ agent: "ai", message: messageToAdd });
          setMessages(() => [
            // we remove the last message from AI because it was incomplete
            ...messagesInMemory.slice(0, messagesInMemory.length - 1),
            // we append the new message
            { agent: "ai", message: messageToAdd },
          ]);
        } else {
          if (!ai_message) return;
          messagesInMemory.push({ agent: "ai", message: ai_message });
          setMessages(() => [
            ...messagesInMemory.slice(0, messagesInMemory.length - 1),
            { agent: "ai", message: ai_message },
          ]);
        }
      }
    });

    // listen for the end of the message stream
    socketInstance.on("message_end", async () => {
      setLoading(false);
    });

    // listen for errors
    socketInstance.on("error", async (data: any) => {
      setLoading(false);
      alert("Error: " + data);
    });

    // listen for the end of the chat. Doesn't necessarily mean the chat is submited just that the process in the backend is no longer there
    socketInstance.on("chat_end", async () => {
      setLoading(false);
      setChatId(null);
      setMessages((m) => [
        ...m,
        { agent: "ai", message: "Chat ended. Reload to start over." },
      ]);
    });

    // cleanup
    return () => {
      setChatId("");
      socket && socket.disconnect();
      setSocket(null);
    };
  }, []);

  // reset chat history and create new chat id
  const resetChat = async () => {
    setMessages([]);
    setChatId("");
    setSubmitted(false);
    generateAndSetConversationStarters(setConversationStarters);
    window.location.reload();
  };

  // send message to be processed by ai
  const emitMessage = async (message: string) => {
    if (!walletAddress) {
      setError("Please connect your wallet.");
      return;
    }
    messagesInMemory.push({ agent: "human", message: message });
    setMessages((m) => [...m, { agent: "human", message: message }]);
    setLoading(true);
    setInput("");

    socket.emit("data", {
      message,
      accessToken: accessToken,
      user_address: walletAddress,
    });
  };

  // submit chat to the creator
  const submitChat = async () => {
    if (messages.length === 0) {
      setError("Please enter a message before submitting the chat.");
      return;
    }

    if (
      window.confirm(
        "Are you sure you want to submit this chat? You have only 3 entries"
      )
    ) {
      if (!walletAddress) {
        setError("Please connect your wallet to submit the chat.");
        return;
      }

      const message = `${walletAddress} submits agent chat ${chatId} at ${new Date().toISOString()}`;
      const signature = await requestSignature(message);

      setLoading(true);
      setInput("");

      await axios.post(
        `${process.env.REACT_APP_CREATOR_ENDPOINT as string}/${chatId}/submit`,
        {
          message: message,
          signature: signature.signature,
          user_address: signature.address,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setLoading(false);
      setSubmitted(true);
    }
  };

  // decide between submit and emit message
  const onSubmit = async (_message?: string) => {
    const message: string = _message || input;
    if (!message) return;
    if (!chatId) {
      return setError(
        "Chat not created yet, please wait for the spinner in the chat to finish loading."
      );
    }

    try {
      if (
        message.toUpperCase() === "COMPLETE" ||
        message.toUpperCase() === "SUBMIT"
      ) {
        submitChat();
      } else {
        emitMessage(message);
      }
    } catch (e: any) {
      setError(e.response.data.error ?? e.message);
      setLoading(false);
    }
  };

  return {
    chatId,
    messages,
    appendMessage: onSubmit,
    loading,
    input,
    setInput,
    resetChat,
    submitted,
    setAccessToken,
    error,
    setError,
    conversationStarters,
    setConversationStarters,
  };
}
