import axios from "axios";
import { useState } from "react";
import ReactConfetti from "react-confetti";
import { useWindowSize } from "react-use";

export default function Submitted(props: { resetForm: () => void }) {
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const { width, height } = useWindowSize();

  async function onSubmitFeedback() {
    if (feedback.trim() === "") {
      return;
    }

    const res = await axios.post(
      (process.env.REACT_APP_CREATOR_ENDPOINT as string) + "/feedback",
      { message: feedback }
    );
    if (res.status !== 200) {
      return alert("Something went wrong, please try again later.");
    }

    setFeedbackSubmitted(true);
  }

  if (showFeedbackForm) {
    return (
      <div className="bg-gray-800 max-w-xl text-center rounded border border-gray-700 mx-auto p-4">
        <h1 className="text-white text-lg font-medium">
          Thank you for taking the time to submit feedback!
        </h1>

        {feedbackSubmitted ? (
          <>
            <p className="text-gray-400 mt-2">
              Your feedback has been submitted successfully. We will use it to
              improve our platform.
            </p>
            <button
              type="button"
              onClick={() => props.resetForm()}
              className="text-white font-medium px-4 py-2 ml-2 mt-4"
            >
              Continue building
            </button>
          </>
        ) : (
          <>
            <p className="text-gray-400 mt-2">
              We will be glad to hear your feedback. We will use it to improve
              our platform.
            </p>
            <form action="">
              <textarea
                name=""
                id=""
                placeholder="Feedback..."
                className="w-full rounded bg-gray-700 p-2 mt-4 text-white"
                onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
              <div className="mt-4">
                <button
                  disabled={feedback.trim() === ""}
                  type="button"
                  onClick={() => onSubmitFeedback()}
                  className="text-white font-medium px-4 ml-2 py-2 bg-blue-500 rounded"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => props.resetForm()}
                  className="text-white font-medium px-4 py-2 ml-2"
                >
                  Continue building
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <ReactConfetti width={width} height={height} />
        <div>
          <div className="bg-gray-800 max-w-xl text-center rounded border border-gray-700 mx-auto p-4">
            <h1 className="text-white text-lg font-medium">
              Successfully submitted agent!
            </h1>
            <p className="text-gray-400 mt-2">
              Your agent has been submitted successfully. You can share it on
              twitter or create another agent.
            </p>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded mt-4"
              onClick={() => {
                window.open(
                  `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    `Surface area is awesome!! I just created ...`
                  )}&hashtags=sphereone,surfacearea,autogen,ai,blockchain`
                );
              }}
            >
              Share on twitter
            </button>
            <button
              onClick={() => props.resetForm()}
              className="text-white font-medium px-4 ml-2"
            >
              Continue building
            </button>
          </div>
          <div className="text-center">
            <button
              className="p-4 text-gray-500 hover:text-gray-200"
              onClick={() => setShowFeedbackForm(true)}
            >
              Submit your feedback
            </button>
          </div>
        </div>
      </div>
    );
  }
}
