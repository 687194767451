import React, { LegacyRef } from "react";
import { MarkDownCode } from "../markdown-code";
import { images } from "../../assets/images";
import { Message } from "../../hooks/use-chat";
import { UserIcon } from "@heroicons/react/20/solid";

interface MessagesProps {
  chatRef?: LegacyRef<HTMLDivElement> | null;
  messages: Message[];
  loading?: boolean;
}

export default function Messages({
  chatRef,
  messages,
  loading,
}: MessagesProps) {
  return (
    <div className="max-h-[90vh] overflow-y-auto py-10 px-4">
      <div
        id="chat"
        ref={chatRef}
        className="flex-col w-full overflow-y-scroll pb-4 max-w-5xl mx-auto"
      >
        {messages.map((msg, index) => (
          <div key={index} className="flex flex-row mb-6">
            <div className="min-w-8">
              <div
                className={`w-8 h-8 rounded-full flex justify-center items-center overflow-hidden ${
                  msg.agent === "human" ? "bg-blue-500" : "bg-green-500"
                }`}
              >
                {msg.agent === "human" ? (
                  <div>
                    <UserIcon className="w-6 h-6 text-white" />
                  </div>
                ) : (
                  <img alt="pfp" src={images.BuilderSupport} />
                )}
              </div>
            </div>
            <div className={`ml-3 max-w-screen-2xl`}>
              <span className="text-[#f3f4f6]">
                {msg.agent === "human" ? "You" : "Surface area"}
              </span>
              <pre className="text-gray-300 text-sm mt-1 break-words whitespace-pre-wrap">
                {msg.agent === "human" ? (
                  msg.message
                ) : !msg.message.includes("typescript") ? (
                  <>
                    {typeof msg.message === "string"
                      ? msg.message
                      : JSON.stringify(
                          JSON.parse((msg.message as any).replace(/'/g, '"')),
                          null,
                          2
                        )}
                  </>
                ) : (
                  <MarkDownCode code={msg.message} title={""} />
                )}
              </pre>
            </div>
          </div>
        ))}

        {loading && (
          <div className="flex flex-row mb-6">
            <div className="min-w-8">
              <div
                className={`w-8 h-8 rounded-full flex justify-center items-center overflow-hidden bg-green-500`}
              >
                <img alt="pfp" src={images.BuilderSupport} />
              </div>
            </div>
            <div className={`ml-3 max-w-screen-2xl`}>
              <span className="text-[#f3f4f6]">Surface area</span>
              <div className="text-gray-300 mt-1 animate-pulse">Loading... This may take a few minutes</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
