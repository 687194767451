import React from "react";
import Markdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark as docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface MarkDownParams {
  code: string;
  title?: string;
}

export const MarkDownCode: React.FC<MarkDownParams> = ({
  code,
  title,
}: MarkDownParams): React.ReactElement => {
  return (
    <div className="w-full my-5">
      {title && <h1 className="text-gray-300 mb-2">{title}</h1>}
      <Markdown
        className="w-full text-sm bg-gray-800 rounded-lg p-2 border max-w-4xl"
        children={code.replace(/\\n/g, " \n")}
        components={{
          code(props) {
            const { children, className, node, ...rest } = props as any;
            const match = /language-(\w+)/.exec(className || "") as any;
            return match ? (
              <SyntaxHighlighter
                {...rest}
                children={String(children).replace(/\n$/, "")}
                language={match[1]}
                style={{
                  ...docco,
                  hljs: {
                    ...docco.hljs,
                    backgroundColor: "transparent",
                  },
                }}
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
      />
    </div>
  );
};
