import CreatorChatHeader from "../components/chat/chat-header";
import ChatEmptyScreen from "../components/chat/chat-empty-screen";
import { useChat } from "../hooks/use-chat";
import Messages from "../components/chat/messages";
import ChatInput from "../components/chat/chat-input";
import Submitted from "../components/chat/submitted";
import { useEffect, useState } from "react";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { auth } from "../utils/firebase";
import { AlertDialog } from "../components/themed-components/alert-dialog";
import { compareProtocolNames } from "../utils/protocols";
import { images } from "../assets/images";
import { generateAndSetConversationStarters } from "../utils/chat";

export default function BuilderGpt() {
  // redeploy on done
  //  return <HackathonFinished />;
  return <BuilderGptView />;
}

function BuilderGptView() {
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const {
    chatId,
    messages,
    loading,
    appendMessage,
    input,
    setInput,
    resetChat,
    submitted,
    setAccessToken,
    error,
    setError,
    conversationStarters,
    setConversationStarters,
  } = useChat();

  const starters =
    window.screen.height > 800 || window.screen.width > 800
      ? conversationStarters
      : conversationStarters.slice(0, 2);

  useEffect(() => {
    generateAndSetConversationStarters(setConversationStarters);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAccessToken(await user.getIdToken(true));
      } else {
        setAccessToken(null);
        signInAnonymously(auth);
      }
    });
  }, []);

  useEffect(() => {
    conversationStarters.forEach((starter) => {
      if (compareProtocolNames(error, starter)) {
        // reset chat if the error message includes a protocol from the conversation starter
        resetChat();
      }
    });
  }, [error]);

  return (
    <div>
      <AlertDialog
        open={!!error}
        title={"Oops!"}
        description={error}
        actionLabel="Accept"
        onActionPress={() => setError("")}
      />
      <AlertDialog
        open={showHelp}
        title={"How to create agents with Surface Area"}
        description={
          <div>
            <p>{"1. Connect wallet"}</p>
            <p>{"2. Type 'Create agent for {protocol}'"}</p>
            <p>3. Review the retrieved protocol information</p>
            <p>{"4. Type 'Create code to integrate {protocol} in my agent'"}</p>
            <p>5. Hit complete to create a submit entry</p>
          </div>
        }
        actionLabel="Ok"
        onActionPress={() => setShowHelp(false)}
      />
      <CreatorChatHeader showHelp={() => setShowHelp(true)} />
      {submitted ? (
        <Submitted resetForm={resetChat} />
      ) : (
        <div className="mx-auto">
          {messages.length === 0 ? (
            <ChatEmptyScreen setInput={setInput} />
          ) : (
            <Messages chatRef={null} messages={messages} loading={loading} />
          )}

          <ChatInput
            disabled={!chatId}
            loading={loading}
            messages={messages}
            input={input}
            setInput={setInput}
            appendMessage={appendMessage}
            onClick={() => appendMessage()}
            conversationStarters={starters}
          />
        </div>
      )}
    </div>
  );
}

function HackathonFinished() {
  return (
    <div className=" max-w-5xl mx-auto p-4 pt-10 text-center">
      <img
        src={images.SphereLogo}
        alt="Sphere logo"
        className="h-10 w-10 rounded-full mx-auto mb-2"
      />
      <h1 className="text-2xl text-gray-300">Hackathon finished</h1>
      <p className="text-gray-400 mt-2">Anyone can create hackathon.</p>
      <p className="text-gray-400">
        Auchhh looks like the hackathon finished already...
      </p>
      <p className="text-gray-400">
        But hey! More is coming! <br /> Join us on our social networks to stay
        tunned!
      </p>
      <div className="flex justify-center items-center mt-4 space-x-3">
        <a
          href="https://twitter.com/sphereone_"
          className="px-3 py-2 text-white rounded bg-blue-500"
        >
          <svg
            className="h-5 w-5 fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
          </svg>
        </a>
        <a
          href="https://discord.gg/txK9cfq7"
          className="px-3 py-2 text-white rounded bg-purple-600"
        >
          <svg
            className="h-5 w-5 fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" />
          </svg>
        </a>
      </div>
    </div>
  );
}
