import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import ConnectWalletButton from "../connect-wallet-button";

export default function CreatorChatHeader(props: { showHelp: () => any }) {
  return (
    <div className="flex flex-row items-center justify-between py-3 px-4 h-14">
      <div className="flex flex-col items-start justify-center">
        <h1 className="text-lg text-white text-nowrap">Surface Area</h1>
      </div>

      <div className="flex space-x-2 items-center">
        <ConnectWalletButton />
        <button onClick={() => props.showHelp()}>
          <QuestionMarkCircleIcon className="h-6 w-6 text-white" />
        </button>
      </div>
    </div>
  );
}
