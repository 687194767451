import ConnectCube from "./Connect.svg";
import Cubes from "./Cubes.svg";
import SphereLogo from "./SphereIconLogoGreen.png";
import BuilderVector from "./builder-vector.png";

export const images = {
  ConnectCube: ConnectCube,
  Cubes: Cubes,
  SphereLogo: SphereLogo,
  BuilderSupport: BuilderVector,
};
