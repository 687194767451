import { Web3 } from "web3";
import { VersionedTransaction } from "@solana/web3.js";
// var Buffer = require("buffer/").Buffer;
// import { Buffer } from "buffer";
import { Buffers } from "@react-frontend-developer/buffers"

export async function requestSignature(message: string) {
  if (!window.ethereum) throw new Error("MetaMask is not installed");

  const web3 = new Web3(window.ethereum);
  const accounts = await web3.eth.getAccounts();
  if (accounts.length === 0) throw new Error("No accounts found");

  const address = accounts[0];
  const signature = await web3.eth.personal.sign(message, address, "");

  return { signature, address };
}

export async function requestSOLSignature(message: string) {
  const provider = window && (window as any).phantom?.solana;

  const bufferized = Buffers.fromString(message, "base64");

  const versionedTransaction = VersionedTransaction.deserialize(bufferized);
  const txSignature = await provider.signAndSendTransaction(
    versionedTransaction
  );

  return txSignature.signature;
}

export function formatWalletAddress(address: string) {
  if (!address || address.length < 8) {
    throw new Error(
      "Invalid address. Address must be at least 8 characters long."
    );
  }
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
}

export function recoverAddress(message: string, signature: string) {
  const web3 = new Web3();
  const recoveredAddress = web3.eth.accounts.recover(message, signature);
  return recoveredAddress;
}
