import defiLlamaData from "../assets/defi-llama.json";

// Function to generate a random agent creation phrase
export const generateRandomAgentCreationPhrase = (): string => {
  const randomIndex = Math.floor(Math.random() * defiLlamaData.length);
  const protocolName = defiLlamaData[randomIndex].name;
  return `Create agent for ${protocolName}`;
};

// Function to generate and set 4 random conversation starters
export const generateAndSetConversationStarters = (
  onStartersGenerated: (newConversationStarters: string[]) => void
) => {
  const newConversationStarters = Array.from(
    { length: 4 },
    generateRandomAgentCreationPhrase
  );
  onStartersGenerated(newConversationStarters);
};
