import ConnectWalletButton from "../connect-wallet-button";
import { images } from "../../assets/images";


export default function ChatEmptyScreen(props: {
  setInput: (str: string) => void;
}) {
  return (
    <div className=" max-w-5xl mx-auto p-4 text-center">
      <img
        src={images.SphereLogo}
        alt="Sphere logo"
        className="h-10 w-10 rounded-full mx-auto mb-2"
      />
      <h1 className="text-2xl text-gray-300">
        Welcome to Surface Area by Sphereone
      </h1>
      <p className="text-gray-400 mt-2">Anyone can create hackathon.</p>
      <p className="text-gray-400">To get started connect your wallet</p>
      <div className="my-4 flex justify-center">
        <ConnectWalletButton />
      </div>
      <p className="text-gray-400">
        Type{" "}
        <code className="bg-gray-800 px-1 rounded">
          Create agent for "protocol"
        </code>
        . <br /> Watch the magic and type{" "}
        <code className="bg-gray-800 px-1 rounded">COMPLETE</code> to submit an
        entry
      </p>
      <p className="text-gray-400 text-xs mt-1">
        *Limit of 3 submits per wallet
      </p>

      {/* <div className="space-y-2 mt-4">
        {conversationStarters.map((option: any, index: number) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              props.setInput(option);
            }}
            key={index}
            className="bg-gray-800 w-3/4 md:w-[40%] p-2 border text-center md:text-start border-gray-600 text-gray-400 rounded-lg text-sm hover:cursor-pointer hover:bg-gray-700"
          >
            <button type="button">{option}</button>
          </div>
        ))}
      </div> */}
    </div>
  );
}
